import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "@layouts/Profile"
import Seo from "@components/seo"
import data from "./_data"
import * as s from "./index.module.less"

const TermsOfUse = () => {
  const itemsFromBuild = useStaticQuery(graphql`
    query {
      soData {
        id
        opening_time
        name
        cancellation_deadline_in_hours
        legal_business_name
        address
        website_url
      }
    }
  `)

  const {
    legal_business_name,
    cancellation_deadline_in_hours,
    address,
    website_url,
  } = itemsFromBuild.soData

  return (
    <Layout>
      <Seo
        title={"Terms of Use"}
        description={`Terms of Use - ${itemsFromBuild.soData.legal_business_name}`}
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-10 col-xl-8">
            <div
              className={s.wrapper}
              dangerouslySetInnerHTML={{
                __html: data
                  .replace(/LEGAL_BUSINESS_NAME/g, legal_business_name)
                  .replace(/WEB_SITE_URL/g, website_url)
                  .replace(/BUSINESS_ADDRESS/g, address)
                  .replace(
                    /CANCELLATION_DEADLINE/g,
                    cancellation_deadline_in_hours
                  ),
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TermsOfUse
