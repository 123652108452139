import { SET_VALUES } from "./types"
import request from "@services/request"

export const getUser = accessToken => {
  return function (dispatch) {
    request("public-users/me", {}, accessToken)
      .then(res => null)
      .catch(e => {
        // dispatch(logout())
      })
  }
}

export const setValues = data => {
  return {
    type: SET_VALUES,
    data,
  }
}
