import React from "react"
import PropTypes from "prop-types"
import * as styles from "./Footer.module.less"

const Footer = () => (
  <footer className={`${styles.footer}`}>
    <div className="row">
      <div className="col-6">
        <p>
          Powered by{" "}
          <a
            href="https://worksimply.com/pro"
            target={"_blank"}
            rel={"noreferrer"}
          >
            Worksimply.com
          </a>
        </p>
      </div>
      <div className="col-6 text-end">
        <ul className={styles.footer_links_home}>
          <li>
            <a href={"/terms-of-use"} target={"_blank"} rel={"noreferrer"}>
              Terms of Use
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
)

export default Footer

Footer.propTypes = {
  footer: PropTypes.string,
  searchPage: PropTypes.bool,
}
