import React from "react"

const data = `<h1>Terms of Use – LEGAL_BUSINESS_NAME</h1>
    <h2>Introduction</h2>
    <p>
      The terms and conditions stated herein (collectively, the <strong>“Terms of Use”</strong>)
      constitute a legal agreement between you and LEGAL_BUSINESS_NAME.,
      a Canadian corporation (<strong>“LEGAL_BUSINESS_NAME”</strong>, <strong>“we”,</strong> <strong>“us”</strong> or <strong>“our”)</strong> having
      its head office at BUSINESS_ADDRESS.The following Terms of Use govern the use of our website
      WEB_SITE_URL (the <strong>“Site”</strong>) and any related services (together with the
      Site, the <strong>“Service”</strong>) made available by LEGAL_BUSINESS_NAME.
    </p>
    <p>
      By accessing, using or participating in the Service, you (<strong>“you”</strong> or <strong>“your”</strong>)
      accept and agree to be bound and comply with these Terms of Use. We may
      modify, add, or delete portions of these Terms of Use, including any
      pricing terms, at any time. If we change these Terms of Use, we will post
      a notice that we have made changes to these Terms of Use on our website
      after the changes are posted and will indicate at the bottom of the Terms
      of Use the date these terms were last revised. Any revisions to these
      Terms of Use will become effective the first time you access or use the
      Service after such changes have been posted.
    </p>
    <p>
      If you do not agree to these Terms of Use, you must cease using the
      Service. If you do not agree to abide by these Terms of Use, you are not
      authorized to use, access or participate in (or continue to use, access or
      participate in) the Service. These Terms of Use constitute a legally
      binding agreement between you and LEGAL_BUSINESS_NAME regarding your use
      of and access to the Service.
    </p>
    <p>
      These Terms of Use include a release by you of all claims for damages
      against us that may arise out of your use of the Service. By accessing or
      using the Service, you are agreeing to this release.
    </p>
    <p>
      These Terms of Use do not alter in any way the terms and conditions of any
      other agreement you may have with LEGAL_BUSINESS_NAME, unless otherwise
      agreed to in writing by LEGAL_BUSINESS_NAME. If you breach any of these
      Terms of Use, your authorization to use the Site automatically terminates
      and you must immediately destroy any Site Content in your possession and
      discontinue all use of the Site.
    </p>
    <p>
      In these Terms of Use, persons that review available Spaces or book a
      Space on the Service are referred to as <strong>“Users”</strong>.
    </p>
    <h2>Privacy</h2>
    <p>
      We may use your email address to verify your identity, protect against
      fraud, and contact you. In addition, we may use it to send you updates
      about your account, new products and services, and Site updates.
    </p>
    <p>
      Personal information you submit to the Site may be employed for the
      purposes of maintaining the integrity of the Site and the content thereon,
      contacting you when necessary, and improving the quality of service
      provided by the Site. LEGAL_BUSINESS_NAME servers also collect information
      specific to how you use the Internet. LEGAL_BUSINESS_NAME servers note
      details such as the server you are logged onto and your IP address. This
      information is obtained solely for the purpose of maintaining the Site’s
      integrity and improving our service. LEGAL_BUSINESS_NAME may ask you for
      more personal information from time to time in a survey format. This
      information is specifically used to ensure that the Site is up-to-date and
      relevant, and that the products and services provided to you in
      advertising format are relevant and of interest to you. Any information
      used as demographics will not reveal the source, and will be kept secure.
      This further information provided by you is on an optional basis.
    </p>
    <p>
      LEGAL_BUSINESS_NAME may employ a data measurement service for tracking the
      performance of an ad on behalf of the advertiser. The information
      collected will not contain personal elements, and will only be shared with
      that particular advertiser.
    </p>
    <p>
      LEGAL_BUSINESS_NAME reserves the right to disclose your personally
      identifiable information as required by law and when we believe that
      disclosure is necessary to protect our rights and/or comply with a
      judicial proceeding, court order, or legal process served on our Site; or
      to protect us and our successors and assigns, and any of our and their
      respective officers, directors, employees, members, agents, and
      representatives. While we make every effort to ensure the integrity and
      security of our network and systems, we cannot guarantee that our security
      measures will prevent third-party “hackers” from illegally obtaining this
      information.
    </p>
    <h2>Proprietary Rights in Site Content and Limited License</h2>
    <p>
      All content on the Site and otherwise available through the Service,
      including Individual Content (as defined below), designs, text, graphics,
      images, video, information, software, audio and other files, and their
      selection and arrangement (the <strong>“Site Content”</strong>), are the proprietary
      property of LEGAL_BUSINESS_NAME, users of the Service or
      LEGAL_BUSINESS_NAME’s licensors, as applicable. No Site Content may be
      modified, copied, distributed, framed, reproduced, republished,
      downloaded, scraped, displayed, posted, transmitted, or sold in any form
      or by any means, in whole or in part, other than as expressly permitted in
      these Terms of Use. You are granted a limited, revocable, non-exclusive
      license to access and use the Service and the Site Content and to download
      or print a reasonable number of copies of portions of the Site Content to
      which you have properly gained access (a) solely for your personal,
      non-commercial use, or (b) solely for the purpose of referring third
      parties to the Service or for compiling a collection of Space listings via
      the Service (a <strong>“Collection”</strong>), provided in each case that you maintain all
      copyright or other proprietary notices on all copies of such Site Content.
    </p>
    <p>
      Except for your own Individual Content or for use in a Collection, you may
      not publish or otherwise distribute Site Content, including via the
      Internet or any intranet or extranet site, or incorporate the Site Content
      in any other database or compilation (other than in a Collection). You may
      not use any data mining, robots, scraping or similar data gathering or
      extraction methods to obtain Site Content. Any use of the Site or the Site
      Content other than as specifically authorized herein is prohibited and
      will automatically terminate your rights with respect to use of the
      Service and the Site Content granted herein. All rights of
      LEGAL_BUSINESS_NAME or its licensors that are not expressly granted in
      these Terms of Use are reserved to LEGAL_BUSINESS_NAME and its licensors.
    </p>
    <h2>Additional Representations and Warranties</h2>
    <p>
      In addition to any other representations and warranties in these Terms of
      Use, you represent and warrant that the Individual Content and all other
      content submitted to the Service through your account or otherwise posted,
      transmitted, or distributed by you on or through the Service (i) does not
      violate or infringe upon the rights of any third party (including
      copyright, trademark, privacy, publicity or other personal or proprietary
      rights); (ii) does not contain libelous, defamatory or otherwise unlawful
      material; and (iii) is truthful and accurate.
    </p>
    <h2>Third Party Websites and Content</h2>
    <p>
      The Site may contain (or you may be sent through the Service) links to
      other web sites (<strong>“Third Party Sites”</strong>) as well as articles, photographs,
      text, graphics, pictures, designs, sound, video, information, and other
      content or items belonging to or originating from third parties (the
      <strong>“Third Party Content”</strong>). Such Third Party Sites and Third Party Content are
      not investigated, monitored or checked for accuracy, appropriateness, or
      completeness by us, and we are not responsible for any Third Party Sites
      accessed through the Site or any Third Party Content posted on, available
      through the Site, including the content, accuracy, offensiveness,
      opinions, reliability, privacy practices or other policies of or contained
      in the Third Party Sites or the Third Party Content. Inclusion of, linking
      to or permitting the use of any Third Party Site or any Third Party
      Content does not imply approval or endorsement thereof by
      LEGAL_BUSINESS_NAME. If you decide to leave the Site and access the Third
      Party Sites or to access or use any Third Party Content, you do so at your
      own risk and you should be aware that our terms and policies no longer
      govern. You should review the applicable terms and policies, including
      privacy and data gathering practices, of any site to which you navigate
      from the Site or relating to any applications you use or install from the
      site.
    </p>
    <h2>Eligibility</h2>
    <p>
      This Service is intended solely for individuals who are 18 years of age or
      older and are able to enter into legally binding contracts. Any
      registration by, use of or access to the Service by anyone under 18 is
      unauthorized and in violation of these Terms of Use. By using the Service,
      you represent and warrant that you are 18 years of age or older and are
      able to enter into legally binding contracts.
    </p>
    <h2>Fees</h2>
    <p>
      If you are a User, when you complete a Space booking on the Service, you
      agree to pay the applicable fees for using the Space as set forth in the
      offering for such Space (the <strong>“Fees”</strong>). We will ask for your credit card
      information and billing address or other payment information, and we will
      process payment for your Fees. We will process the payment for your
      booking within 24 hours of the date of reservation.
    </p>
    <h2>Payment</h2>
    <p>
      The payment of funds between Users and LEGAL_BUSINESS_NAME, may be
      provided by Stripe. Your use of Payment Processing is subject to the
      Stripe Connected Account Agreement that includes the Stripe Services
      Agreement as may be modified by Stripe from time to time (collectively,
      the <strong>“Stripe Agreement”</strong>). As a condition of Payment Processing, you must
      provide accurate and complete information about you and your business and
      you authorize us to share this information to Stripe. All bank and credit
      card information is sent directly to and stored with Stripe using their
      security protocols. LEGAL_BUSINESS_NAME does not store your payment
      information on its systems and shall not have any responsibility for the
      safety or security of that information. Your use of Payment Processing is
      conditioned upon your compliance with the Stripe Agreement, and if the
      Stripe Agreement is terminated by Stripe you may not be able to use the
      Service, or have your Account suspended or terminated. We may change or
      add other payment processing services at any time in our sole discretion,
      which may be subject to additional terms or conditions.
    </p>
    <h2>Cancellation policy for day passes and on-demand offices</h2>
    <p>
      You agree to LEGAL_BUSINESS_NAME’s cancellation policy. You may cancel a
      Space reservation up to CANCELLATION_DEADLINE hours prior to the start of
      the booking. If you wish to do so, please send an email to
      the mentioned email addresses in the confirmation email and we'll cancel the booking for you. Bookings
      cancelled with less than such CANCELLATION_DEADLINE hours’ notice will be
      charged in full. Unless otherwise indicated, bookings are non-refundable
      once confirmation has been made by the User and the Provider.
      Refunds/cancellations may be subject to a processing fee.
    </p>
    <h2>Your Conduct at Spaces</h2>
    <p>
      By completing a reservation for a Space via the Service, each User
      acknowledges and agrees to abide by any rules, regulations or guidelines
      (<strong>“Space Rules”</strong>) of each Space that you book and agree to be solely liable
      for any non-compliance, and any damages or liability to the Space caused
      by your actions, inactions and breach of these Terms of Use. By completing
      a reservation for a Space via the Service you agree to do all of the
      following in connection with your use of the Service and to otherwise use
      any Space in compliance with these Terms of Use and Space Rules:
    </p>
    <ul>
      <li>
        accept the terms, conditions, and policies provided by the Provider
        relating to the use of the Space, including building security
        procedures, IT access and use procedures, maximum occupancy limitations
        and other terms or procedures provided by the Provider.
      </li>
      <li>
        behave in an appropriate manner and will not do or attempt to do
        anything illegal at any Space. Further, you shall not use or permit the
        usage of any illegal drug or substance and shall not make or permit any
        unreasonable or unnecessary noises or odours in or upon any Space.
      </li>
      <li>
        shall not commit, or suffer to be committed, any waste upon any Space or
        any nuisance (public or private) or other act or thing of any kind or
        nature whatsoever that may disturb the quiet enjoyment or cause
        unreasonable annoyance of any other occupants in the Space.
      </li>
      <li>
        shall not make alterations, additions or improvements to any Space,
        including the installation of any fixtures, equipment, machinery or
        appliances.
      </li>
      <li>will not smoke or vape in any Space.</li>
      <li>
        will follow all additional regulations regarding any Space, as may be
        communicated through the Site or any applications you use or install
        from the site.
      </li>
      <li>
        you are responsible for leaving any Space in a clean and tidy condition.
      </li>
      <li>
        neither LEGAL_BUSINESS_NAME nor any Provider is responsible for any
        property you may leave behind in a Space. It is your responsibility to
        ensure that you have retrieved all of your personal items prior to
        leaving a Space.
      </li>
      <li>
        you may be held liable (and do hereby authorize LEGAL_BUSINESS_NAME to
        charge you) for the repair cost for all damage to any Space and items
        therein during your use thereof.
      </li>
      <li>
        you will only use an access point or wireless internet connection which
        you are authorized to use. Additionally, when using the internet within
        any Spaces, you shall only use it for lawful purposes, and without
        restricting the foregoing, you shall not use it for purposes of criminal
        acts, consumer fraud, etc.
      </li>
    </ul>
    <p>
      You will at all times follow these Terms of Use and Space Rules while at
      any Space. Consequently, as a User, you agree to abide by, and cause your
      employees, agents, guests, invitees, contractors, directors, shareholders,
      members and representatives to abide by, these Term of Use.
    </p>
    <h2>Limitation of Liability</h2>
    <p>
      IN NO EVENT WILL LEGAL_BUSINESS_NAME OR ITS DIRECTORS, EMPLOYEES, AGENTS
      OR AFFILIATES, BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT,
      CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES,
      INCLUDING FOR ANY LOST PROFITS, LOST DATA, PERSONAL INJURY OR PROPERTY
      DAMAGE, OF ANY NATURE WHATSOEVER, ARISING FROM YOUR USE OF THE SERVICE,
      ANY CONTENT OR OTHER MATERIALS ON, ACCESSED THROUGH OR DOWNLOADED FROM THE
      SERVICE, OR A USER’S USE OF A SPACE, EVEN IF LEGAL_BUSINESS_NAME IS AWARE
      OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. WITHOUT LIMITING
      THE FOREGOING, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
      EVENT WILL LEGAL_BUSINESS_NAME BE RESPONSIBLE OR LIABLE IN ANY MANNER FOR
      ANY LOSS OR DAMAGE ARISING OUT OF ANY TRANSACTIONS, COMMUNICATIONS,
      INTERACTIONS OR DISPUTES BETWEEN A USER AND A PROVIDER. NOTWITHSTANDING
      ANYTHING TO THE CONTRARY CONTAINED HEREIN, AND TO THE MAXIMUM EXTENT
      PERMITTED BY APPLICABLE LAW, LEGAL_BUSINESS_NAME’S LIABILITY TO YOU OR ANY
      PARTY CLAIMING THROUGH YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE
      FORM OF THE ACTION, IS LIMITED TO THE GREATER OF (1) THE AMOUNT PAID, IF
      ANY, BY YOU TO LEGAL_BUSINESS_NAME FOR THE SERVICE IN THE 12 MONTHS PRIOR
      TO THE INITIAL ACTION GIVING RISE TO LIABILITY AND (2) $250 IN THE
      AGGREGATE FOR ALL CLAIMS. THIS IS AN AGGREGATE LIMIT. THE EXISTENCE OF
      MORE THAN ONE CLAIM HEREUNDER WILL NOT INCREASE THIS LIMIT.
    </p>
    <h2>Termination</h2>
    <p>
      LEGAL_BUSINESS_NAME may terminate or suspend your account or ability to
      use the Service, in whole or in part, without notice in the event that (i)
      you violate these Terms of Use, any applicable Space Rules or violate any
      other rules that govern the use of the Service, (ii) we determine, in our
      sole discretion, that your conduct may harm LEGAL_BUSINESS_NAME or others
      or cause LEGAL_BUSINESS_NAME or others to incur liability, (iii) you
      receive significant negative feedback, as determined by us in our sole
      discretion, from Providers or Users, as applicable, or (iv) we elect to do
      so in our sole discretion. If we terminate or suspend your account or
      ability to use the Service, (a) any pending or accepted future
      reservations as either User or Provider will be immediately terminated;
      (b) we may communicate to your Users or Providers that such reservations
      have been cancelled; (c) for Providers, we may refund your Users in full
      for any and all confirmed reservations; and (d) you will not be entitled
      to any compensation for reservations that were cancelled as a result of a
      suspension or termination of your account.
    </p>
    <p>
      LEGAL_BUSINESS_NAME may modify the Service, and all content, software and
      other items used or contained in the Service, at any time. References on
      the Service to any companies, organizations, products, or services, or
      other information do not constitute or imply endorsement, sponsorship or
      recommendation thereof, or any affiliation therewith, by
      LEGAL_BUSINESS_NAME.
    </p>
    <p>
      You may terminate your LEGAL_BUSINESS_NAME account at any time by
      providing LEGAL_BUSINESS_NAME with notice of termination in accordance
      with the instructions available through the Service. Your access to, use
      of, or participation in the Service, including any content therein, may be
      prevented by LEGAL_BUSINESS_NAME at any time after your termination of
      your account. Notwithstanding anything herein to the contrary, upon
      termination of your account, LEGAL_BUSINESS_NAME will remove your Profile
      and cease displaying your Profile Content, including your Space listings
      if you are a Provider and your Collections, on or through the Service.
    </p>
    <h2>Indemnification</h2>
    <p>
      You agree to indemnify, defend, and hold harmless LEGAL_BUSINESS_NAME and
      its successors and assigns, and any of their respective officers,
      directors, employees, members, agents, representatives, licensors,
      advertisers, and suppliers from any liability, loss, claim, and expense
      (including reasonable legal fees) related to (a) your violation of these
      Terms of Use, and (b) your use of the Service.
    </p>
    <p>
      We reserve the right to assume, at our sole expense, the exclusive defense
      and control of any such claim or action and all negotiations for
      settlement, or compromise, and you agree to fully cooperate with us in the
      defense of any such claim, action, settlement or compromise negotiations,
      as we may request.
    </p>
    <h2>General</h2>
    <p>
      These Terms of Use constitutes the entire agreement between you and
      LEGAL_BUSINESS_NAME relating to your use and our provision of the Service.
    </p>
    <p>
      You agree that LEGAL_BUSINESS_NAME may provide you with notices, including
      those regarding changes to these Terms of Use, by email, regular mail, or
      postings on the Site.
    </p>
    <p>
      You agree that if LEGAL_BUSINESS_NAME does not exercise or enforce any
      legal right or remedy which is contained in these Terms of Use (or which
      LEGAL_BUSINESS_NAME has the benefit of under any applicable law), this
      will not be taken to be a formal waiver of LEGAL_BUSINESS_NAME’s rights
      and that those rights or remedies will still be available to
      LEGAL_BUSINESS_NAME.
    </p>
    <p>
      If any provision of these Terms of Use is held to be illegal, invalid or
      unenforceable, this will not affect any other provision of these Terms of
      Use and the agreement between you and us will be deemed amended to the
      extent necessary to make it legal, valid, and enforceable.
    </p>
    <p>
      The laws of the Province of Ontario and the federal laws applicable
      therein shall govern these Terms of Use in all respects, without giving
      effect to conflicts of laws principles.
    </p>
    <br><br>
    <p><strong>Last revised on September 10, 2021</strong></p>
 `

export default data
