import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import * as styles from "./index.module.less"
import Footer from "../Footer"
import qs from "query-string"
import { useDispatch, useSelector } from "react-redux"
import { setValues } from "../../stores/ProfileStore/actions"

const Layout = ({ children }) => {
  /**
   * This is the template of the results page
   * If the required params are found in the URL, this will display the results
   * If not, the user will be taken to /onboard
   * Until the required parameters are finalized, the page will display blank
   */

  const [homeLink, setHomeLink] = useState("/")
  const queryParams = qs.parse(
    typeof window !== "undefined" ? window.location.search : ""
  )

  const [logo] = useState(
    require(`../../images/clients/${
      process.env.GATSBY_CLIENT_SLUG || "default"
    }/logo.svg`).default
  )

  const dispatch = useDispatch()
  const reservation = useSelector(state => state.profile.reservation)

  useEffect(() => {
    let homeLink = "/"
    if (queryParams.su_space_type) {
      homeLink += `?space_type=${queryParams.su_space_type}`
    }
    if (queryParams.su_current_staff) {
      homeLink += `&current_staff=${queryParams.su_current_staff}`
    }
    if (queryParams.su_budget_min && queryParams.su_budget_max) {
      homeLink += `&budget_min=${queryParams.su_budget_min}&budget_max=${queryParams.su_budget_max}`
    }
    if (queryParams.su_move_in_date && queryParams.su_move_in_date !== "all") {
      homeLink += `&move_in_date=${queryParams.su_move_in_date}`
    }
    if (
      queryParams.su_move_out_date &&
      queryParams.su_move_out_date !== "all"
    ) {
      homeLink += `&move_in_date=${queryParams.su_move_out_date}`
    }
    setHomeLink(homeLink)
  }, [queryParams])

  return (
    <div
      className={`${reservation ? styles.reservation_active : ""} ${
        styles.profile_wrapper
      }`}
    >
      <header className={styles.header}>
        <div className="container">
          <div className={"profile-header-theme-wrapper"}>
            <Link
              to={homeLink}
              onClick={() => dispatch(setValues({ reservation: false }))}
            >
              {logo && (
                <img
                  src={logo}
                  alt={process.env.GATSBY_CLIENT_NAME}
                  className={styles.logo}
                />
              )}
            </Link>
          </div>
        </div>
      </header>
      <main>{children}</main>
      <div className={`container ${styles.footer_wrapper}`}>
        <Footer />
      </div>
    </div>
  )
}

export default Layout
